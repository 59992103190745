import { GetServerSidePropsContext } from "next"
import { destroyCookie, parseCookies } from "nookies"
import jwt from "jsonwebtoken"
import { JWT_SECRET, TOKEN_COOKIES } from "@const/shared"

export function serverTokenChecker(ctx: GetServerSidePropsContext): string | null {
  const parsedCookies = parseCookies(ctx)
  const token = parsedCookies.HSTOKENID

  if (!token) {
    return null
  }

  jwt.verify(token, JWT_SECRET, { algorithms: ["HS256"] }, (err) => {
    if (err) {
      destroyCookie(ctx, TOKEN_COOKIES, {})
      return null
    }
  })

  return token
}




export function isInAppChecker(): boolean {
  const nookies = parseCookies()
  const userAgent = nookies["HS_UG"]

  if (!userAgent) {
    return false
  }

  switch (true) {
    case userAgent.includes("Instagram"):
    case userAgent.includes("Twitter"):
    case userAgent.includes("FB_IAB"):
    case userAgent.includes("FBAV"):
    // case userAgent.includes("iPhone"):
    // case userAgent.includes("iPad"):
      return true

    default:
      return false
  }

  
}


