import {
  CheckTokenBody,
  CheckTokenResponse,
  EditProfileResponse,
  GoogleLoginBody,
  GoogleLoginData,
  GoogleLoginResponse,
  GoogleNewUserResponse,
  LoginManualBody,
  LoginResponse,
  ProfileBody,
  RawTokenData,
  RawUserTraits,
  RegisterBody,
  RegisterResponse,
  ResetPasswordBody,
  SubmitPasswordBody,
  UserPictureResponse,
  UserTraits,
} from "@interfaces/auth"
import { deleteTokenCookies, domainChecker, getTokenInfo, setTokenCookies } from "@lib/functions"
import AuthRepo from "@repositories/auth.repositories"
import { destroyCookie, setCookie } from "nookies"
import { Result } from "./response"

export default class AuthServices extends AuthRepo {
  async register(body: RegisterBody) {
    try {
      const res = await this.rawRegister(body)

      const response = await this._getResponse<RegisterResponse>(res)

      const { error, result, error_code, error_data } = response

      if (error || error_code || !result) {
        switch (true) {
          case error_code === "NOT_VERIFIED":
            return Result.fail<RegisterResponse>(
              "Email sudah terdaftar, silahkan cek email dan verifikasi akun kamu"
            )

          case error_code === "REQUIRED": {
            const errorData: { validation?: string }[] = error_data

            const validation = errorData[0].validation
            let errorMessage = ""

            switch (validation) {
              case "unique":
                errorMessage = "Email yang kamu masukan sudah digunakan"
                break

              case "email":
                errorMessage =
                  "Email yang kamu masukan salah."
                break

              default:
                errorMessage = "Email sudah terdaftar."
                break
            }

            return Result.fail<RegisterResponse>(errorMessage)
          }

          default:
            return Result.fail<RegisterResponse>(
              "Terjadi Kesalahan. Pastikan form terisi dengan benar dan silahkan coba lagi"
            )
        }
      }

      // const tokenData = setTokenCookies(result)

      // if (!tokenData) {
      //   return Result.fail<RegisterResponse>(
      //     "Terjadi Kesalahan. Pastikan form terisi dengan benar dan silahkan coba lagi"
      //   )
      // }

      // if (tokenData.data?.status_completed_profile) {
      //   destroyCookie(null, "HS_TEMP")
      // }

      return Result.ok<RegisterResponse>(result)
    } catch (e: any) {
      return Result.fail<RegisterResponse>(
        "Terjadi Kesalahan. Pastikan form terisi dengan benar dan silahkan coba lagi"
      )
    }
  }

  async manualLogin({ email, password }: LoginManualBody) {
    try {
      const res = await this.rawLogin({ user_email: email, user_password: password })

      const response = await this._getResponse<RawTokenData | LoginResponse>(res)

      const { error, result, error_code } = response

      if (error || !result) {
        switch (error_code) {
          case "REQUIRED":
            return Result.fail<RawTokenData>("Terjadi Kesalahan. Silahkan coba lagi")
          case "WRONG_PASSWORD":
            return Result.fail<RawTokenData>("Password yang kamu masukkan salah")
          case "NOT_FOUND":
            return Result.fail<RawTokenData>("Email belum terdaftar")

          default: {
            return Result.fail<RawTokenData>()
          }
        }
      }

      const tokenData = setTokenCookies(result as LoginResponse)

      if (!tokenData) {
        return Result.fail<RawTokenData>(
          "Terjadi Kesalahan. Pastikan form terisi dengan benar dan silahkan coba lagi"
        )
      }

      if (tokenData.data?.status_completed_profile) {
        destroyCookie(null, "HS_TEMP")
      }

      return Result.ok<RawTokenData>(tokenData)
    } catch (e: any) {
      const errorData = await this._getErrorObject(e)
      const errorCode = errorData.error_code

      switch (errorCode) {
        case "NOT_VERIFIED":
          return Result.fail<RawTokenData>(
            "Akun ini belum diaktifkan. Silahkan cek email kamu untuk mengaktifkan akun"
          )
        case "DEACTIVATED":
          return Result.fail<RawTokenData>("Akun ini sudah dinonaktifkan")
        case "SUSPENDED":
          return Result.fail<RawTokenData>("Akun ini telah ditangguhkan")

        default: {
          return Result.fail<RawTokenData>("Terjadi kesalahan. Silahkan coba lagi")
        }
      }
    }
  }

  async googleLogin({ email, fullName, googleId }: GoogleLoginBody) {
    try {
      const res = await this.rawGoogleLogin({ userFullname: fullName, googleId, email })

      const response = await this._getResponse<GoogleNewUserResponse | GoogleLoginResponse>(res)

      const { result } = response

      if (!result) {
        return Result.fail<GoogleLoginData | GoogleNewUserResponse>(
          "Terjadi Kesalahan. Silahkan coba lagi"
        )
      }

      if (result.new_user === 0) {
        const loginResult = result as GoogleLoginResponse
        const rawTokenData = setTokenCookies(loginResult)

        if (!rawTokenData) {
          return Result.fail<GoogleLoginData>("Terjadi Kesalahan. Silahkan coba lagi")
        }

        destroyCookie(null, "HS_TEMP")
        const tokenData = rawTokenData.data
        return Result.ok<GoogleLoginData>({
          ...tokenData,
          new_user: 0,
        })
      }

      const loginResult = result as GoogleNewUserResponse
      setCookie(null, "HS_TEMP", JSON.stringify(loginResult), {
        path: "/",
        domain: domainChecker(),
      })

      return Result.ok<GoogleNewUserResponse>(loginResult)
    } catch (e: any) {
      const errorData = await this._getErrorObject(e)
      const errorCode = errorData.error_code

      switch (errorCode) {
        case "ACCOUNT_HAS_BEEN_DEACTIVATED":
          return Result.fail<GoogleNewUserResponse>("Akun ini sudah dinonaktifkan")
        case "USER_ACCOUNT_SUSPENDED":
          return Result.fail<GoogleNewUserResponse>("Akun ini telah ditangguhkan")
        case "USER_ALREADY_EXISTS":
          return Result.fail<GoogleNewUserResponse>(
            "Email sudah terdaftar, silahkan cek email dan verifikasi akun kamu."
          )

        default: {
          return Result.fail<GoogleNewUserResponse>("Terjadi Kesalahan. Silahkan coba lagi")
        }
      }
    }
  }

  async submitGooglePassword(body: SubmitPasswordBody) {
    try {
      const res = await this.rawSubmitGooglePassword(body)

      const response = await this._getResponse<LoginResponse>(res)

      const { error, result, error_code } = response

      if (error || error_code || !result) {
        return Result.fail<LoginResponse>(
          "Terjadi Kesalahan. Pastikan form terisi dengan benar dan silahkan coba lagi"
        )
      }

      setTokenCookies(result)

      destroyCookie(null, "HS_TEMP")
      return Result.ok<LoginResponse>(result)
    } catch (e: any) {
      return Result.fail<LoginResponse>(
        "Terjadi Kesalahan. Pastikan form terisi dengan benar dan silahkan coba lagi"
      )
    }
  }

  async editProfile(body: Partial<ProfileBody>) {
    try {
      const res = await this.rawUpdateProfile(body)

      const response = await this._getResponse<EditProfileResponse>(res)
      const { error, result, error_code } = response

      if (error || error_code || !result) {
        return Result.fail<EditProfileResponse>()
      }

      destroyCookie(null, "HS_TEMP")
      await this.refreshToken()

      return Result.ok<EditProfileResponse>(result)
    } catch (e: any) {
      return Result.fail<EditProfileResponse>()
    }
  }

  async editProfilePicture(body: FormData) {
    try {
      const res = await this.rawUpdateProfilePicture(body)

      const response = await this._getResponse<UserPictureResponse>(res)
      const { error, result, error_code } = response

      if (error || error_code || !result) {
        return Result.fail<UserPictureResponse>()
      }

      await this.refreshToken()

      return Result.ok<UserPictureResponse>(result)
    } catch (e: any) {
      return Result.fail<any>()
    }
  }

  async editInterest(interest: string[]) {
    try {
      const res = await this.rawUpdateInterest(interest)

      const response = await this._getResponse<any>(res)

      const { error, error_code } = response

      if (error || error_code) {
        return Result.fail<any>()
      }

      destroyCookie(null, "HS_TEMP")
      await this.refreshToken()
      return Result.ok<{ message: string }>({ message: "Success" })
    } catch (e: any) {
      return Result.fail<any>()
    }
  }

  async resendVerificationEmail(email: string) {
    try {
      const res = await this.rawResendVerification(email)
      const response = await this._getResponse<any>(res)
      const { error, result, error_code } = response

      if (error || error_code || !result) {
        return Result.fail<any>()
      }
      return Result.ok<{ message: string }>({ message: "Success" })
    } catch (e: any) {
      return Result.fail<any>()
    }
  }

  async logout() {
    deleteTokenCookies()

    try {
      await this.rawLogout()
    } catch (e: any) {
      return Result.fail<any>()
    }
  }

  async resetPassword(email: string) {
    try {
      const res = await this.rawForgotPassword(email)
      const response = await this._getResponse<any>(res)
      const { error, result, error_code } = response

      if (error || error_code) {
        if (error_code === "WRONG_EMAIL") {
          return Result.fail("Email kamu belum terdaftar")
        } else {
          return Result.fail()
        }
      }

      return Result.ok<any>({ message: "success" })
    } catch (e: any) {
      return Result.fail<any>()
    }
  }

  async checkResetToken(body: CheckTokenBody) {
    try {
      const res = await this.rawCheckTokenReset(body)
      const response = await this._getResponse<CheckTokenResponse>(res)
      const { error, result, error_code } = response

      if (error || error_code || !result) {
        if (error_code === "TOKEN_EXPIRED") {
          return Result.fail<CheckTokenResponse>("Token sudah kadaluarsa")
        } else {
          return Result.fail<CheckTokenResponse>()
        }
      }

      return Result.ok<CheckTokenResponse>(result)
    } catch (e: any) {
      return Result.fail<CheckTokenResponse>()
    }
  }

  async resetNewPassword(body: ResetPasswordBody) {
    try {
      const res = await this.rawResetPassword(body)
      const response = await this._getResponse<any>(res)
      const { error, result, error_code } = response

      if (error || error_code || !result) {
        if (error_code === "TOKEN_EXPIRED") {
          return Result.fail<any>("Token sudah kadaluarsa")
        } else {
          return Result.fail()
        }
      }

      return Result.ok<any>(result)
    } catch (e: any) {
      return Result.fail<any>()
    }
  }

  async changePassword(oldPassword: string, newPassword: string) {
    try {
      const res = await this.rawChangePassword(oldPassword, newPassword)
      const response = await this._getResponse<any>(res)
      const { error, result, error_code } = response

      if (error || error_code || !result) {
        if (error_code === "WRONG_PASSWORD") {
          return Result.fail<any>("Password lama yang kamu masukan tidak cocok")
        } else {
          return Result.fail()
        }
      }

      return Result.ok<any>({ message: "success" })
    } catch (e: any) {
      return Result.fail()
    }
  }

  async refreshToken() {
    try {
      const { refreshToken } = getTokenInfo()

      const res = await this.rawRefreshToken({ refresh_token: refreshToken })
      const response = await this._getResponse<any>(res)
      const { result } = response

      if (result) {
        setTokenCookies(result)
      }
    } catch (e: any) {
      return Result.fail()
    }
  }

  async getUserData() {
    try {
      const res = await this.rawGetUserData()

      if (!res.ok) {
        return Result.fail<UserTraits>("Terjadi Kesalahan. Silahkan coba lagi")
      }

      const result = await this._getRawResult<RawUserTraits>(res)

      return Result.ok<UserTraits>(result.traits)
    } catch (error) {
      return Result.fail<UserTraits>("Terjadi Kesalahan. Silahkan coba lagi")
    }
  }
}
